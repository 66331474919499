import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _708abc80 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _618e9673 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _08f67e88 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _40d26c43 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _fdd6259c = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _4c5160fc = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _04450c21 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _57ccda4e = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _706feb52 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _1842a3b1 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _63f7b2ae = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _330349ec = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _260eb14e = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _6de5ec4f = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _399bf4d3 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _1af29072 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _508ba71a = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _7b2b5af9 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _16ddeb75 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _7df7e0dc = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _33fd2100 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _254f128f = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _5261fd04 = () => interopDefault(import('../pages/onboarding/confirmation.vue' /* webpackChunkName: "pages/onboarding/confirmation" */))
const _73fe4b94 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _6e67b716 = () => interopDefault(import('../pages/onboarding/enhanced-quotes.vue' /* webpackChunkName: "pages/onboarding/enhanced-quotes" */))
const _b1949be6 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _7e303520 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _833c582a = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _4c9b671e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/index" */))
const _21962b70 = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _f061455a = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _46e4399a = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _13f0dd0a = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _32fd8966 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _63bac401 = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _6b5393bc = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _1d17d886 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _0a866462 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _68fdcd6c = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _3667d9aa = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _398609fe = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _44e66c5f = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _b0c986e0 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _01b14bd9 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _20608940 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _3e97e7fd = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _57455aeb = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/index" */))
const _34c23b52 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/index" */))
const _4ad687b2 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/index" */))
const _b9907ec6 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bathing-and-showering.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bathing-and-showering" */))
const _56680b6d = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bladder-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bladder-control" */))
const _6234391a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bowel-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bowel-control" */))
const _2837cf42 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/dressing.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/dressing" */))
const _75805b46 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/feeding.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/feeding" */))
const _b1918a56 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/mobility-indoors.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/mobility-indoors" */))
const _967c0aa6 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/progression-over-time.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/progression-over-time" */))
const _130dd4e2 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/review" */))
const _6c7f8612 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/transferring.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/transferring" */))
const _0418eb53 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/alcohol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/alcohol" */))
const _1cb2802d = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/general" */))
const _33507ba3 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/review" */))
const _11db489e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/smoking.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/smoking" */))
const _56f8e4d0 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/cancer.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/cancer" */))
const _12f07e5a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/diabetes.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/diabetes" */))
const _12963ac8 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/general" */))
const _5f759c9a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol" */))
const _2e5a3c94 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/hypertension.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/hypertension" */))
const _e38d9dac = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis" */))
const _0da9bc9e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/neurological.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/neurological" */))
const _750d81a8 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/review" */))
const _14e977d0 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _0b857132 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _708abc80,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _618e9673,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _08f67e88,
    name: "help"
  }, {
    path: "/verify-email",
    component: _40d26c43,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _fdd6259c,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _4c5160fc,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _04450c21,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _57ccda4e,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _706feb52,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _1842a3b1,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _63f7b2ae,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _330349ec,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _260eb14e,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _6de5ec4f,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _399bf4d3,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _1af29072,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _508ba71a,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _7b2b5af9,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _16ddeb75,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _7df7e0dc,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _33fd2100,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _254f128f,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/confirmation",
    component: _5261fd04,
    name: "onboarding-confirmation"
  }, {
    path: "/onboarding/employment-info",
    component: _73fe4b94,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/enhanced-quotes",
    component: _6e67b716,
    name: "onboarding-enhanced-quotes"
  }, {
    path: "/onboarding/fund-selection",
    component: _b1949be6,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _7e303520,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _833c582a,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/medical-questionnaire",
    component: _4c9b671e,
    name: "onboarding-medical-questionnaire"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _21962b70,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _f061455a,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _46e4399a,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _13f0dd0a,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _32fd8966,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _63bac401,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _6b5393bc,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _1d17d886,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _0a866462,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _68fdcd6c,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _3667d9aa,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _398609fe,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _44e66c5f,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _b0c986e0,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _01b14bd9,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _20608940,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _3e97e7fd,
    name: "personal-retirement"
  }, {
    path: "/onboarding/medical-questionnaire/ADL",
    component: _57455aeb,
    name: "onboarding-medical-questionnaire-ADL"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle",
    component: _34c23b52,
    name: "onboarding-medical-questionnaire-lifestyle"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment",
    component: _4ad687b2,
    name: "onboarding-medical-questionnaire-medical-assessment"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bathing-and-showering",
    component: _b9907ec6,
    name: "onboarding-medical-questionnaire-ADL-bathing-and-showering"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bladder-control",
    component: _56680b6d,
    name: "onboarding-medical-questionnaire-ADL-bladder-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bowel-control",
    component: _6234391a,
    name: "onboarding-medical-questionnaire-ADL-bowel-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/dressing",
    component: _2837cf42,
    name: "onboarding-medical-questionnaire-ADL-dressing"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/feeding",
    component: _75805b46,
    name: "onboarding-medical-questionnaire-ADL-feeding"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/mobility-indoors",
    component: _b1918a56,
    name: "onboarding-medical-questionnaire-ADL-mobility-indoors"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/progression-over-time",
    component: _967c0aa6,
    name: "onboarding-medical-questionnaire-ADL-progression-over-time"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/review",
    component: _130dd4e2,
    name: "onboarding-medical-questionnaire-ADL-review"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/transferring",
    component: _6c7f8612,
    name: "onboarding-medical-questionnaire-ADL-transferring"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/alcohol",
    component: _0418eb53,
    name: "onboarding-medical-questionnaire-lifestyle-alcohol"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/general",
    component: _1cb2802d,
    name: "onboarding-medical-questionnaire-lifestyle-general"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/review",
    component: _33507ba3,
    name: "onboarding-medical-questionnaire-lifestyle-review"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/smoking",
    component: _11db489e,
    name: "onboarding-medical-questionnaire-lifestyle-smoking"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/cancer",
    component: _56f8e4d0,
    name: "onboarding-medical-questionnaire-medical-assessment-cancer"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/diabetes",
    component: _12f07e5a,
    name: "onboarding-medical-questionnaire-medical-assessment-diabetes"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/general",
    component: _12963ac8,
    name: "onboarding-medical-questionnaire-medical-assessment-general"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/high-cholesterol",
    component: _5f759c9a,
    name: "onboarding-medical-questionnaire-medical-assessment-high-cholesterol"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/hypertension",
    component: _2e5a3c94,
    name: "onboarding-medical-questionnaire-medical-assessment-hypertension"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis",
    component: _e38d9dac,
    name: "onboarding-medical-questionnaire-medical-assessment-multiple-sclerosis"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/neurological",
    component: _0da9bc9e,
    name: "onboarding-medical-questionnaire-medical-assessment-neurological"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/review",
    component: _750d81a8,
    name: "onboarding-medical-questionnaire-medical-assessment-review"
  }, {
    path: "/blog/:slug?",
    component: _14e977d0,
    name: "blog-slug"
  }, {
    path: "/",
    component: _0b857132,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
